<template>
  <div class="home_wrap">

    <van-nav-bar
      title="Membro da equipe"
        left-text=""
        left-arrow
        @click-left="onClickLeft"
    />


    <div class="table_wrap">
      <el-table
          :data="tableData"
          size="mini"
          :height="'90%'"
          :default-sort = "{prop: 'id', order: 'descending'}"
          :lazy="true"

          :empty-text="'Você não convidou amigos'"
          >
        <el-table-column
            prop="id"
            label="ID"
            align="center"
            sortable


            >
        </el-table-column>
        <el-table-column
            prop="total_bet_amount"
            label="Aposta"
            align="center"
            sortable

           >
        </el-table-column>
        <el-table-column prop="total_recharge_amount" label="Depósito" align="center" sortable show->
        </el-table-column>

      </el-table>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import {NavBar} from 'vant';
import Fetch from '../../utils/fetch';

Vue.use(NavBar);
export default {
  name: "teamList",
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    this.getSubUsers();
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    getSubUsers(){
      Fetch("/user/subLevelUser").then(r=>{
        this.tableData = r.data;
      })
    },
    getSummaries(param) {

      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = 'Total';
          return;
        }
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = 'R$';
        } else {
          sums[index] = 'N/A';
        }
      });

      return sums;
    }
  }
}
</script>

<style scoped>
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  /*background-color: transparent !important;*/
}
/deep/ .el-table th{
  background-color: rgb(21, 29, 41) !important;
  border: 0;
}
/deep/ .el-table td{
  border-top: thin solid #303030;
  background-color: rgb(21, 29, 41);
  border-bottom: 0;
}
/deep/ .el-table__empty-block{
  background: rgb(21, 29, 41);
  border-top: thin solid #303030;
}

/deep/.el-table th.el-table__cell.is-leaf {
  border: 0;
}
/deep/ .el-table::before {
  display: none;
}
.txt_center{
  text-align: center;
}
/deep/ .van-nav-bar {
  background: rgb(21, 29, 41);
}

/deep/ .van-nav-bar .van-icon {
  color: #fff;
}

/deep/ .van-nav-bar__title {
  color: #fff;
}

.home_wrap {
  width: 100%;
  min-height: 100vh;
  background-color: rgb(48, 48, 48);
}
.table_wrap{
  width: 90%;
  margin: 0.5rem  auto;
  border-radius: 0.1rem;
  overflow: hidden;
  height: 90vh;

}
</style>